import React from 'react'
import './FetchingMessage.css'
import { Loader } from 'monday-ui-react-core'

const FetchingMessage = ({ message }) => {
  return (
    <span className="fetching-message">
      <Loader className="fetching-loading" size={20} color={Loader.colors.PRIMARY} /> {message}
      <div className="loading">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </span>
  )
}

export default FetchingMessage
