import React, { createContext, useContext, useState, useEffect } from 'react'
import mondaySdk from 'monday-sdk-js'

const monday = mondaySdk()
monday.setApiVersion('2024-01')

const Context = createContext()

export const ContextProvider = ({ children }) => {
  const [contextData, setContextData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const result = await monday.get('context')
      if (result.data) {
        setContextData(result.data)
      }
    }

    fetchData()
  }, [])

  return <Context.Provider value={contextData}>{children}</Context.Provider>
}

export const useContextData = () => useContext(Context)
