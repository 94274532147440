import { Button, DatePicker, Dialog, IconButton } from 'monday-ui-react-core'
import React from 'react'
import { formatDate, formatDateWeekFilter } from '../../util/utils'
import { Close } from 'monday-ui-react-core/icons'
import './CustomDateFilter.css'
import 'monday-ui-react-core/dist/main.css'

const CustomDateFilter = ({
  dateRange,
  setDateRange,
  label = 'Date filter',
  isDisabled = false,
  btnText = 'Select a Date',
}) => {
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      {label}
      <Dialog
        content={
          <DatePicker
            data-testid="date-picker"
            date={dateRange.startDate}
            endDate={dateRange.endDate}
            range
            className="date-picker"
            onPickDate={(date) => setDateRange(date)}
          />
        }
        showTrigger={!isDisabled && ['click']}
        hideTrigger={['click', 'clickoutside', 'esckey']}
        wrapperClassName="date-picker-dialog"
      >
        <div className="custom-date-content">
          <Button disabled={isDisabled} size={Button.sizes.XS}>
            {dateRange?.startDate && dateRange?.endDate
              ? `${formatDateWeekFilter(new Date(dateRange?.startDate))} -
            ${formatDateWeekFilter(new Date(dateRange?.endDate))}`
              : btnText}
          </Button>
          {dateRange?.startDate && dateRange?.endDate && (
            <IconButton
              onClick={() => setDateRange({ startDate: null, endDate: null })}
              size={IconButton.sizes.XS}
              color={IconButton.colors.ON_PRIMARY_COLOR}
              className="close-icon"
              icon={Close}
            />
          )}
        </div>
      </Dialog>
    </div>
  )
}

export default CustomDateFilter
