import React, { useEffect, useMemo, useState } from 'react'
import { useTable, useResizeColumns, useFlexLayout } from 'react-table'
import {
  Chips,
  DatePicker,
  Dialog,
  Dropdown,
  Flex,
  IconButton,
  Loader,
  TextArea,
} from 'monday-ui-react-core'
import { CloseSmall, Check, Edit, Delete } from 'monday-ui-react-core/icons'

import InputField from '../common/InputField'
import { formatDate, formatDateForInput, setColumnMinWidth } from '../../util/utils'
import { readOnlyColumns } from '../../util/constants'
import './TimesheetItemsSection.styles.css'
import moment from 'moment'

const TimesheetItemsTable = ({
  tableData,
  tableColumns,
  editIndex,
  handleSaveEdit,
  handleEditButton,
  users,
  handleInputChange,
  handleAddItem,
  handleCancelAddItem,
  createItem,
  contextData,
  statuses,
  isAddingItem,
  handleDeleteLog,
  isSavingEdit,
}) => {
  const [statusValue, setStatusValue] = useState([])
  const [textAreaValue, setTextAreaValue] = useState('')
  const [pickedDate, setPickedDate] = useState(null)
  useEffect(() => {
    setStatusValue([])
    setTextAreaValue('')
  }, [editIndex, createItem])

  const data = useMemo(() => {
    return tableData?.map((dataItem) => {
      const transformedData = dataItem?.column_values?.map((col, index) => {
        if (col?.columnNotFound) {
          return {
            [tableColumns[index]?.title]: JSON.stringify({
              value: 'columnNotFound',
              columnId: '',
              options: [],
            }),
          }
        }

        const options =
          statuses?.find(
            (status) =>
              parseInt(status.boardId) === parseInt(dataItem.boardId) &&
              status.columnId === (col?.id || col?.columnId),
          )?.values || []
        return {
          [tableColumns[index]?.title]: JSON.stringify({
            value: col?.text || '',
            columnId: col?.id,
            options: options,
          }),
        }
      })
      return Object.assign({}, ...transformedData)
    })
  }, [tableData, tableColumns, statuses])

  const columns = useMemo(() => {
    if (tableColumns.length > 0) {
      const newTableColumns = [
        ...new Map(tableColumns.map((item) => [item['title'], item])).values(),
      ]
      return newTableColumns?.map((col, i) => ({
        Header: col.title,
        accessor: col.title,
        minWidth: setColumnMinWidth(col),
        width: col.title === 'Edit' && 80,
      }))
    }
  }, [tableColumns])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useResizeColumns,
  )

  const handleAddClick = async () => {
    await handleAddItem()
    setPickedDate(null)
  }

  const handleSaveEditClick = async () => {
    await handleSaveEdit()
    setPickedDate(null)
  }

  const actionIcons = (index) => {
    return editIndex === index ? (
      <Flex>
        {isSavingEdit ? (
          <div style={{ width: 40, display: 'flex', justifyContent: 'center' }}>
            <Loader size={20} />
          </div>
        ) : (
          <IconButton
            disabled={isAddingItem}
            onClick={() => {
              createItem ? handleAddClick() : handleSaveEditClick()
            }}
            icon={Check}
          />
        )}
        <IconButton
          disabled={isAddingItem || isSavingEdit}
          onClick={() => {
            handleCancelAddItem()
            setPickedDate(null)
          }}
          icon={CloseSmall}
        />
      </Flex>
    ) : (
      <Flex>
        <IconButton onClick={() => handleEditButton(index)} icon={Edit} />
        <IconButton onClick={() => handleDeleteLog(index)} icon={Delete} />
      </Flex>
    )
  }

  const getAvatar = (user) => {
    const foundUser = users.find((val) => val.label === user)
    if (foundUser) {
      return foundUser.leftAvatar
    }
  }

  const handleGetUserEmail = (data) => {
    const columnFound = data.find((val) => val.column.Header === 'Worker')

    if (columnFound && columnFound.value) {
      const userFound = users.find((user) => user?.value === columnFound.value)
      if (userFound) {
        return userFound.email
      }
    }

    const userFound = users.find((user) => user.value === contextData.user.id)
    if (userFound) {
      return userFound.email
    }
  }

  const getContextUserName = () => {
    const userFound = users.find((user) => user.value === contextData.user.id)
    if (userFound) {
      return userFound.label
    }
  }

  const handleStatusChange = (val, columnId) => {
    setStatusValue((prevState) => {
      const currentStatusValues = [...prevState]
      const statusFound = currentStatusValues.find((status) => status.columnId === columnId)
      if (statusFound) {
        statusFound.label = val.label
        statusFound.value = val.label
      } else {
        currentStatusValues.push({ value: val.label, label: val.label, columnId: columnId })
      }
      return currentStatusValues
    })
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance
  return (
    <div className="table-container">
      {tableColumns?.length ? (
        <table
          {...getTableProps()}
          className="table"
          style={{ borderCollapse: 'collapse', maxWidth: '100%', overflow: 'hidden' }}
        >
          <thead className="table-head-style sticky-header">
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    {...column.getResizerProps()} // Allow resizing
                    className={`${index === 0 && 'sticky-cell'} header-style`}
                    style={{
                      ...column.getHeaderProps().style,
                    }}
                    key={column.id}
                  >
                    {column.render('Header')}
                    <div {...column.getResizerProps()} className="header-column" />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr key={i} {...row.getRowProps()} className="table-row">
                    {row.cells.map((cell, index) => {
                      const column = tableColumns[index]
                      const cellDataJSON = cell.value ? JSON.parse(cell.value) : {}
                      const cellData = cellDataJSON?.value
                      const cellOptions = cellDataJSON?.options
                      let options = []
                      if (Object.keys(cellOptions || {})?.length) {
                        const arrayOptions = cellOptions.map((val) => Object.values(val))
                        if (arrayOptions?.flat()?.length) {
                          options = arrayOptions.flat().map((val) => ({
                            value: val,
                            label: val,
                          }))
                        }
                      }
                      if (index === 0) {
                        return (
                          <td
                            className={`${column.title === 'Edit' && 'sticky-cell'}`}
                            {...cell.getCellProps()}
                          >
                            {actionIcons(i)}
                          </td>
                        )
                      }

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${cellData === 'columnNotFound' && 'disabled-cell'}`}
                        >
                          {cellData === 'columnNotFound' ? (
                            ''
                          ) : editIndex === i && cellData !== 'columnNotFound' ? (
                            column.title === 'Worker' ? (
                              <Dropdown
                                disabled
                                className="dropdown-stories-styles_with-chips dropdown-menu"
                                placeholder="Select a Worker"
                                insideOverflowWithTransformContainer={true}
                                menuPosition={Dropdown.menuPositions.FIXED}
                                // onChange={(val) => handleInputChange(val, i, column, row.original)}
                                defaultValue={users.find(
                                  (user) => user.label === getContextUserName(),
                                )}
                                // options={users}
                                dropdownMenuWrapperClassName="menu-wrapper-style"
                                readOnly
                                size="xs"
                              />
                            ) : column.type === 'status' ? (
                              <Dropdown
                                className="dropdown-stories-styles_with-chips dropdown-menu"
                                placeholder="Select a Status"
                                insideOverflowWithTransformContainer={true}
                                menuPosition={Dropdown.menuPositions.FIXED}
                                clearable={false}
                                value={
                                  statusValue.find((option) => option?.columnId === column.title) ||
                                  options?.find((option) => option.label === cellData)
                                }
                                onChange={(val) => {
                                  handleStatusChange(val, column.title)
                                  handleInputChange(val?.label, i, column, row.original)
                                }}
                                dropdownMenuWrapperClassName="menu-wrapper-style"
                                options={options}
                                size="xs"
                              />
                            ) : column.title === 'Work Time Notes' ? (
                              <TextArea
                                className="text-area-style"
                                value={textAreaValue || cellData}
                                onChange={(e) => {
                                  handleInputChange(e.target.value, i, column, row.original)
                                  setTextAreaValue(e.target.value)
                                }}
                              />
                            ) : column.title === 'Work Date' ? (
                              <Dialog
                                content={
                                  <DatePicker
                                    data-testid="date-picker"
                                    className="date-picker"
                                    date={
                                      pickedDate
                                        ? pickedDate
                                        : moment(cellData.replace(/-/g, '/') || new Date())
                                    }
                                    onPickDate={(date) => {
                                      handleInputChange(
                                        formatDateForInput(date._d),
                                        i,
                                        column,
                                        row.original,
                                      )
                                      setPickedDate(date)
                                    }}
                                  />
                                }
                                showTrigger={['click']}
                                hideTrigger={['click', 'clickoutside', 'esckey']}
                                wrapperClassName="date-picker-dialog"
                              >
                                <div className="table-date-picker">
                                  {formatDate(
                                    pickedDate?._d
                                      ? new Date(pickedDate?._d)
                                      : cellData
                                        ? new Date(cellData.replace(/-/g, '/'))
                                        : new Date(),
                                  )}
                                </div>
                              </Dialog>
                            ) : (
                              <InputField
                                disabled={readOnlyColumns.find((col) => col === column.title)}
                                handleOnChange={(value) =>
                                  handleInputChange(value, i, column, row.original)
                                }
                                isTextArea={column.title === 'Work Time Notes'}
                                title=""
                                value={
                                  column.title === 'Email' && !cellData
                                    ? handleGetUserEmail(row.cells)
                                    : column.title === 'Block Work Time' && cellData
                                      ? parseFloat(cellData).toFixed(2)
                                      : cellData
                                }
                                type={column.type}
                              />
                            )
                          ) : column.title === 'Worker' && column.type === 'text' ? (
                            cellData ? (
                              <Chips
                                label={cellData}
                                leftAvatar={getAvatar(cellData)}
                                readOnly
                                className="chip-style"
                              />
                            ) : (
                              ''
                            )
                          ) : column.title === 'Work Date' ? (
                            formatDate(new Date(cellData.replace(/-/g, '/')))
                          ) : column.title === 'Block Work Time' ? (
                            parseFloat(cellData).toFixed(2)
                          ) : (
                            cellData
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={tableColumns.length} className="no-items">
                  No items to show
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className="no-items">No items to show</div>
      )}
    </div>
  )
}

export default TimesheetItemsTable
