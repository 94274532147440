import { Button, Chips, Icon, IconButton, Tooltip } from 'monday-ui-react-core'
import { AddSmall, Check, Filter, Rotate } from 'monday-ui-react-core/icons'
import React, { useEffect, useRef, useState } from 'react'
import CustomDateFilter from '../CustomDateFilter/CustomDateFilter'
import WeekCycleFilter from '../WeekCycleFilter/WeekCycleFilter'
import { getWeekRange } from '../../util/utils'
import 'monday-ui-react-core/dist/main.css'
import './Filters.css'
import FetchingMessage from '../common/FetchingMessage'
import { filterOptions } from '../../util/constants'

const Filters = ({
  boardInitialized,
  setTotalHours,
  totalHours,
  dateRange,
  setDateRange,
  setCurrentWeek,
  currentWeek,
  setCreateItem,
  isFetching,
  isAddingItem,
  showSuccess,
  setRefreshData,
  selectedFilter,
  setSelectedFilter,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const menuRef = useRef()

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.code === 'Escape') {
        setShowDropdown(false)
      }
    }

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const handlePreviousWeek = () => {
    setTotalHours(0)
    setDateRange({ startDate: null, endDate: null })
    const previousWeekStart = new Date(currentWeek.startDate)
    previousWeekStart.setDate(previousWeekStart?.getDate() - 7)
    setCreateItem(false)
    setCurrentWeek(getWeekRange(previousWeekStart))
  }

  const handleNextWeek = () => {
    setTotalHours(0)
    setDateRange({ startDate: null, endDate: null })
    const nextWeekStart = new Date(currentWeek.startDate)
    nextWeekStart.setDate(nextWeekStart?.getDate() + 7)
    setCreateItem(false)
    setCurrentWeek(getWeekRange(nextWeekStart))
  }

  const handleSelectFilterOptions = (option) => {
    setSelectedFilter(option)
    setShowDropdown(false)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', gap: 10 }}>
        <div>
          {showSuccess && (
            <span className="success-message">
              <Chips
                className="success-chip"
                color={Chips.colors.DONE_GREEN}
                label="Log created successfully"
                readOnly
              />
            </span>
          )}
          {isAddingItem && !isFetching && !showSuccess && (
            <FetchingMessage message="Creating new log" />
          )}
          {isFetching && !isAddingItem && !showSuccess && (
            <FetchingMessage message="Getting items" />
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'end', textWrap: 'nowrap', gap: 16 }}>
          <Tooltip content={!boardInitialized && 'Board is not Initialized'}>
            <Button
              style={{ marginTop: 'auto' }}
              size={Button.sizes.SMALL}
              rightIcon={AddSmall}
              onClick={() => setCreateItem(true)}
              disabled={isAddingItem || !boardInitialized}
            >
              New Log
            </Button>
          </Tooltip>
          <div style={{ display: 'flex', gap: 2 }}>
            <Button
              style={{ marginTop: 'auto' }}
              size={Button.sizes.SMALL}
              rightIcon={Rotate}
              onClick={() => setRefreshData(true)}
              disabled={isAddingItem}
            >
              Refresh
            </Button>
            <div ref={menuRef} className="filter-button">
              <Button
                onClick={() => setShowDropdown((prevState) => !prevState)}
                size={IconButton.sizes.SMALL}
                className={`${selectedFilter.id !== 'default' && 'filter-changed'}`}
              >
                <Icon icon={Filter} />
              </Button>
              {showDropdown && (
                <div className={'filter-dropdown'}>
                  {filterOptions.map((option) => (
                    <div
                      onClick={() => handleSelectFilterOptions(option)}
                      className={`${option.id === selectedFilter.id && 'filter-option-selected'} filter-option`}
                    >
                      <span className="checkmark">
                        {option.id === selectedFilter.id && <Check />}
                      </span>{' '}
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="filters">
        <CustomDateFilter setDateRange={setDateRange} dateRange={dateRange} />
        <WeekCycleFilter
          handleNextWeek={handleNextWeek}
          handlePreviousWeek={handlePreviousWeek}
          currentWeek={currentWeek}
        />
        <Chips label={`Total hours: ${parseFloat(totalHours).toFixed(2)}`} readOnly />
      </div>
    </div>
  )
}

export default Filters
