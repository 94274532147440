import React from 'react'
import { TextField } from 'monday-ui-react-core'
import { formatDateForInput } from '../../util/utils'

const InputField = ({
  title = '',
  type,
  value,
  handleOnChange,
  disabled = false,
  isValid = true,
}) => {
  return (
    <div>
      <TextField
        className="input-style"
        disabled={disabled}
        onChange={(value, e) => handleOnChange(value)}
        title={title}
        type={type}
        value={type === 'date' ? formatDateForInput(new Date(value)) : value}
        validation={{ status: !isValid && 'error' }}
      />
    </div>
  )
}

export default InputField
