import { Button, Dropdown, Loader } from 'monday-ui-react-core'
import React, { useEffect, useState } from 'react'
import { Search, MoveArrowDown, MoveArrowUp } from 'monday-ui-react-core/icons'
import mondaySdk from 'monday-sdk-js'

import {
  dataFromLocalStorage,
  doTimelinesOverlap,
  formatDate,
  getCustomRulesBoardsData,
  getCustomRulesData,
  getHeaderWidth,
} from '../../util/utils'
import { useContextData } from '../../context/mondayContext'
import { getAllItemsOnBoard } from '../../services/Monday.service'
import InputField from '../common/InputField'
import AssignedOnItemsTable from './AssignedOnItemsTable'
import CustomDateFilter from '../CustomDateFilter/CustomDateFilter'
import FetchingMessage from '../common/FetchingMessage'

const monday = mondaySdk()
monday.setApiVersion('2024-01')

const AssignedOnItemsSection = ({
  selectedWorkspaces,
  storageValues,
  handleClickedRow,
  savedBoardsList,
  slug,
}) => {
  const [showTable, setShowTable] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [itemName, setItemName] = useState('')
  const [boards, setBoards] = useState(savedBoardsList || [])
  const [selectedBoards, setSelectedBoards] = useState([])
  const [isValid, setIsValid] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [showInfo, setShowInfo] = useState(true)
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  })
  const contextData = useContextData()

  useEffect(() => {
    if (selectedWorkspaces.length && contextData?.user) {
      setTableData([])
      setTableColumns([])
      setShowTable(false)
      setData([])
    }
    handleGetBoard()
  }, [selectedWorkspaces, contextData])

  const handleGetBoard = async () => {
    const workspaceIds = selectedWorkspaces?.map((workspace) => workspace?.value)
    await getCustomRulesData(workspaceIds, contextData.account.id, slug)
    const localStorageData = dataFromLocalStorage(contextData.account.id)
    const localstorageBoards = localStorageData.savedBoards

    if (localstorageBoards) {
      setBoards(localstorageBoards)
    }
  }

  const handleSetTableStructure = (data) => {
    const columnsData = Object.values(
      data
        .flatMap((board) =>
          board?.column_values?.reduce((acc, item) => {
            if (item.column.title === 'Timeline') {
              const columnData = {
                id: item.id,
                title: item?.column?.title,
                width: getHeaderWidth(item.type),
                type: item.type,
              }

              acc[columnData.title] = columnData
            }
            return acc
          }, {}),
        )
        .reduce((acc, obj) => {
          return { ...acc, ...obj }
        }, {}),
    )

    if (!columnsData?.length) {
      columnsData.unshift({ id: 'timeline', title: 'Timeline', width: 200 })
      columnsData.unshift({ id: 'name', title: 'Name', width: 200 })
      columnsData.unshift({ id: 'boardName', title: 'Board Name', width: 200 })
    } else {
      columnsData.unshift({ id: 'name', title: 'Name', width: 200 })
      columnsData.unshift({ id: 'boardName', title: 'Board Name', width: 200 })
    }

    const updatedBoards = data.reduce((acc, item) => {
      const updatedColumnValues = columnsData?.map((column) => {
        if (column.title === 'Board Name') {
          return {
            column: { title: column.title },
            value: item?.boardId || item?.board?.id,
            text: item?.boardName || item?.board?.name,
          }
        }
        if (column.title === 'Name') {
          return {
            column: { title: column.title },
            value: item.id,
            text: item.name,
          }
        }
        const timelineColumn = item.column_values.find((col) => col.column.title === 'Timeline')
        if (column.title === 'Timeline') {
          let timelineValue = ''
          if (timelineColumn && timelineColumn.value) {
            const parsedTimeline = JSON.parse(timelineColumn.value)
            timelineValue = `${formatDate(new Date(parsedTimeline.from))}-${formatDate(new Date(parsedTimeline.to))}`
          }
          return {
            column: { title: column.title },
            value: timelineValue,
            text: timelineValue,
          }
        }
        return null
      })
      acc.push({
        ...item,
        column_values: updatedColumnValues.filter((col) => col !== null),
      })

      return acc
    }, [])

    setTableColumns(columnsData)
    setTableData(updatedBoards)
    setIsLoading(false)
  }

  const handleSearch = async () => {
    let boardIds = []
    if (selectedBoards?.length) {
      boardIds = selectedBoards?.map((board) => board.value)
    }

    let allItems = []
    setIsLoading(true)
    if (itemName && boardIds?.length) {
      let boardItems = []
      for (const boardId of boardIds) {
        const res = await getCustomRulesBoardsData(boardId, itemName, slug)
        if (res.length) {
          boardItems.push(res)
        }
      }
      allItems = boardItems.flat().filter((val) => {
        return val?.column_values?.some((col) => {
          if (col.type === 'people' && col?.value) {
            const workerIds = JSON.parse(col.value)?.personsAndTeams
            if (workerIds.find((val) => val.id === parseInt(contextData?.user?.id))) {
              return true
            }
          }
          return false
        })
      })
    } else if (!itemName && boardIds?.length) {
      let boardItems = []
      for (const boardId of boardIds) {
        const res = await getAllItemsOnBoard(boardId, slug)
        if (res.length) {
          boardItems.push(res)
        }
      }
      allItems = boardItems.flat().filter((val) => {
        return val?.column_values?.some((col) => {
          if (col.type === 'people' && col?.value) {
            const workerIds = JSON.parse(col.value)?.personsAndTeams
            if (workerIds.find((val) => val.id === parseInt(contextData?.user?.id))) {
              return true
            }
          }
          return false
        })
      })
    }

    if (dateRange?.startDate && dateRange?.endDate) {
      allItems = allItems.filter((val) => {
        const timelineColumn = val?.column_values?.find((col) => col.column.title === 'Timeline')
        if (timelineColumn && timelineColumn?.value) {
          const { to, from } = JSON.parse(timelineColumn.value)
          if (doTimelinesOverlap(from, to, dateRange.startDate, dateRange.endDate)) {
            return val
          }
        }
        return false
      })
    }

    setData(allItems)
    handleSetTableStructure(allItems)
    setIsLoading(false)
    setShowTable(true)
  }

  const handleSelectBoards = (value) => {
    if (!isValid && value?.length) {
      setIsValid(true)
    }
    setSelectedBoards(value)
  }

  const handleInputName = (value) => {
    if (!isValid && value) {
      setIsValid(true)
    }
    setItemName(value)
  }

  return (
    <div>
      <div className="show-info" onClick={() => setShowInfo((prevState) => !prevState)}>
        {showInfo ? (
          <div className="show-info-button">
            <MoveArrowUp />
            Hide Information
          </div>
        ) : (
          <div className="show-info-button">
            <MoveArrowDown />
            Show Information
          </div>
        )}
      </div>
      <div className={`info-content ${showInfo ? 'expanded' : 'collapsed'}`}>
        The search will only show items that you are currently assigned on (in any of the “people“
        type columns in boards) <br /> <br /> To use the search, first you will need to select the
        boards that you want to search in (or the search button will be disabled), and then you can
        filter by the item name and if the item has a “timeline“ set, you can filter by the items
        timeline as well. <br /> <br />
        You can use just the filter for boards to search or all the 2 - 3 filters combined <br />{' '}
        <br />
        Once you have set your filters and have clicked on the search button, you will be shown
        items in a table that you can click on and create a new time entry with in the table above.
      </div>
      {isFetching && <FetchingMessage message={'Syncing'} />}
      <div className="assigned-on-search">
        <div style={{ width: 250, display: 'flex', flexDirection: 'column' }}>
          <div className="input-label">Boards</div>
          <Dropdown
            options={boards.sort((a, b) => {
              if (a.label > b.label) return 1
              if (a.label < b.label) return -1
              return 0
            })}
            value={selectedBoards}
            multi
            dropdownMenuWrapperClassName="menu-wrapper-style"
            onChange={(val) => handleSelectBoards(val)}
            className={`dropdown-stories-styles_with-chips boards-menu-style ${!isValid && 'menu-style-invalid'}`}
            placeholder="Select Boards"
            size={Dropdown.sizes.SMALL}
          />
        </div>
        <div style={{ width: 200, display: 'flex', flexDirection: 'column' }}>
          <div className="input-label">Item Name</div>
          <InputField
            isValid={isValid}
            disabled={!selectedBoards?.length}
            handleOnChange={(val) => handleInputName(val)}
            type={'text'}
          />
        </div>
        <div className="date-filter-style">
          <CustomDateFilter
            isDisabled={!selectedBoards?.length}
            label=""
            setDateRange={setDateRange}
            dateRange={dateRange}
            btnText="Timeline Filter"
          />
        </div>
        <div className="search-btn">
          <Button
            disabled={!selectedBoards?.length}
            size={Button.sizes.SMALL}
            onClick={handleSearch}
            leftIcon={Search}
          >
            Search
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Loader className="loader-style" size={40} color={Loader.colors.PRIMARY} />
        </div>
      ) : (
        showTable && (
          <AssignedOnItemsTable
            handleClickedRow={handleClickedRow}
            tableData={tableData}
            tableColumns={tableColumns}
            userAssignedBoards={data}
            storageValues={storageValues}
            slug={slug}
          />
        )
      )}
    </div>
  )
}

export default AssignedOnItemsSection
