export const mondayStorageKeyValues = {
  mondayStorageFolderId: 'mondayStorageFolderId',
  selectedBoards: 'selectedBoards',
  selectedFolders: 'selectedFolders',
  temporarySelectedBoards: 'temporarySelectedBoards',
  status1: 'status1',
  status2: 'status2',
}

export const localStorageKeyValues = {
  savedWorkspaces: 'savedWorkspaces',
  savedTimesheetItems: 'savedTimesheetItems',
  savedAssignedOnItems: 'savedAssignedOnItems',
  savedBoards: 'savedBoards',
}

export const readOnlyColumns = [
  'Subitem',
  'Board Name',
  'Name',
  'Worker',
  'Email',
  'Actual Hours',
  'Item ID',
  'Project ID',
  'Subitems',
  'Timesheet Submission Status',
  'Work Time Tracker',
  'Timesheet',
  'Person',
  'Date',
  'Log Job Time',
  'Log Subitem Time',
]

export const usedColumns = [
  'Worker',
  'Email',
  'Work Date',
  // 'Actual Hours',
  // 'Notes',
  'Block Work Time',
  'Work Time Notes',
]

export const itemDefaultColumns = [
  { title: 'Subitem', type: 'text' },
  { title: 'Worker', type: 'text' },
  { title: 'Email', type: 'email' },
  { title: 'Work Date', type: 'date' },
  { title: 'Actual Hours', type: 'numbers' },
  { title: 'Notes', type: 'long_text' },
  { title: 'Item ID', type: 'text' },
  { title: 'Project ID', type: 'text' },
]

export const isNumberReggex = /^-?(\d+)?(\.\d+)?$/

export const filterOptions = [
  { label: 'Current and last week', id: 'default' },
  { label: 'Current selected range', id: 'selected' },
  { label: 'Last 30 days', id: '30days' },
  { label: 'All (may take longer to refresh)', id: 'all' },
]
