import React from 'react'
import { Chips, IconButton } from 'monday-ui-react-core'
import { formatDateWeekFilter } from '../../util/utils'
import { DropdownChevronRight, DropdownChevronLeft } from 'monday-ui-react-core/icons'
import './WeekCycleFilter.css'

const WeekCycleFilter = ({ handleNextWeek, handlePreviousWeek, currentWeek }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      Filter by week
      <IconButton
        onClick={handlePreviousWeek}
        color={IconButton.colors.ON_PRIMARY_COLOR}
        className="week-arrow-icon"
        size={IconButton.sizes.XS}
        icon={DropdownChevronLeft}
      />
      <Chips
        label={`${formatDateWeekFilter(currentWeek.startDate)} - ${formatDateWeekFilter(currentWeek.endDate)}`}
        readOnly
      />
      <IconButton
        onClick={handleNextWeek}
        color={IconButton.colors.ON_PRIMARY_COLOR}
        className="week-arrow-icon"
        size={IconButton.sizes.XS}
        icon={DropdownChevronRight}
      />
    </div>
  )
}

export default WeekCycleFilter
